import React, { useEffect, useState} from 'react';
import Screen from '../shared/screen';
import { jdfInstance } from '../../http/http';
import ProductPage from './product';
function MallPage(props){

    const [products,setProducts] = useState([]);
    const [search,setSearch] = useState("");
    const [orderBy,setOrderBy] = useState("default");
    const [isViewProduct, setIsViewProduct] = useState(false);
    const [productId, setProductId] = useState(0);
    useEffect(()=>{
        getProducts();
    },[orderBy]);

    // APIS  ========================================================================================

     async function getProducts(){
        let formData = new FormData();
        formData.append("search",search);
        formData.append("orderBy",orderBy);

        const url = "products/getProducts.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            setProducts(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    // FUNCTIONS ========================================================================================

    function handleOnChange(val){
        setSearch(val);
    }

    function handleOrderChange(e){
        setOrderBy(e.target.value);
    }

    function handleSearch(){
        getProducts();
    }

    function clickProduct(productId){
        setIsViewProduct(true);
        setProductId(productId);
    }

    // PROPS FUNCTIONS ========================================================================================

    function handleBack(){
        setIsViewProduct(false);
        setProductId(0);
    }
    // RENDER ========================================================================================

    return(
        <Screen pageSlug="mall">
            {!isViewProduct && (<div className="container mx-auto px-4 py-8">
                {/* Search Bar */}
                <div className="flex justify-center mb-6">
                    <input
                        type="text"
                        id="searchText"
                        className="border border-gray-300 rounded-l-lg p-2 w-2/3"
                        placeholder="Search by Redeem Name"
                        value={search}
                        onChange={(e) => handleOnChange(e.target.value)}
                    />
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded-r-lg"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                </div>

                {/* Product Count and Sorting */}
                <div className="flex justify-between items-center mb-6">
                    <p>Showing {products.length} entries of products</p>
                    <select
                        className="border border-gray-300 p-2 rounded-lg"
                        id="orderBy"
                        value={orderBy}
                        onChange={handleOrderChange}
                    >
                        <option value="default">Default</option>
                        <option value="latest">Sort by Latest</option>
                        <option value="high">Sort by Price: High to Low</option>
                        <option value="low">Sort by Price: Low to High</option>
                    </select>
                </div>

                {/* Product Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {products.length > 0 ? (
                        products.map((product) => (
                            <div
                                key={product.id}
                                className="bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col"
                            >
                                <img
                                    src={product.product_image}
                                    alt={product.product_name}
                                    className="w-full h-48 object-cover cursor-pointer"
                                    onClick={() => clickProduct(product.id)}
                                />
                                <div className="p-4 flex-grow">
                                    <p className="font-bold text-lg mb-2">
                                        {product.product_name.length > 40
                                            ? `${product.product_name.substring(0, 40)}...`
                                            : product.product_name}
                                    </p>
                                </div>
                                <div className="text-center p-1">
                                    <button
                                        onClick={() => clickProduct(product.id)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                                    >
                                        View Redeem
                                    </button>
                                </div>
                                <div className="text-center p-1 text-lg text-teal-500 font-bold">
                                    DT {product.cf_amount}
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center col-span-full text-gray-500">
                            No products listing at the moment, please stay tuned for more products!
                        </p>
                    )}
                </div>
            </div>)}
            {isViewProduct && <ProductPage productId={productId} handleBack={handleBack}/>}
        </Screen>
    );
}

export default MallPage;