import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { jdfInstance } from '../../http/http';
import { AppBar, Toolbar,Typography,Stepper,Step,StepLabel,Button, Divider, TextField, InputAdornment,OutlinedInput,InputLabel,IconButton,FormControl,Tooltip,Select,MenuItem,Accordion,AccordionSummary,AccordionDetails } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Check from '@mui/icons-material/Check';
import Search from '@mui/icons-material/Search';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function SignupPage(props) {
    let params = useParams();
    
    const [uplineId, setUplineId] = useState("99999999");
    const [uplineName, setUplineName] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [packages, setPackages] = useState([]);
    const [memberInfo,setMemberInfo] = useState({email:"",password:"",fullname:"",gender:"male",race:"",dob:"",mobile_number:"",country:"Malaysia",address:"",bank_name:"",bank_account:"",package:"",payment_method:"",receipt:"",waiver_name:"",waiver_date:""})
    const [isNewUser,setIsNewUser] = useState(false);
    const [isCheckedEmail,setIsCheckedEmail] = useState(false);
    const [isViewPassword,setIsViewPassword] = useState(false);
    const [stepsCanProceed,setStepsCanProceed] = useState([false,false,false,false,false]);
    const [consentForm,setConsentForm] = useState([false,false,false]);
    const [waiverForm,setWaiverForm] = useState([false,false,false,false]);

    useEffect(()=>{
        checkMemberId(params);

    },[params]);
    
    useEffect(()=>{
        getPackages();
    },[]);
    
    useEffect(()=>{
        if(memberInfo.email.length > 0 && memberInfo.password.length > 0){
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[0] = true;
            setStepsCanProceed(newStepsCanProceed);
        }else{
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[0] = false;
            setStepsCanProceed(newStepsCanProceed);
        }
    },[memberInfo.email,memberInfo.password]);

    useEffect(()=>{
        if(memberInfo.package !== "" && memberInfo.fullname.length > 0 && memberInfo.mobile_number.length > 0 && memberInfo.dob.length > 0 && memberInfo.address.length > 0){
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[2] = true;
            setStepsCanProceed(newStepsCanProceed);
        }else{
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[2] = false;
            setStepsCanProceed(newStepsCanProceed);
        }
    },[memberInfo.package,memberInfo.fullname,memberInfo.mobile_number,memberInfo.address,memberInfo.dob]);

    useEffect(()=>{
        if(memberInfo.payment_method !== ""){
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[3] = true;
            setStepsCanProceed(newStepsCanProceed);
        }
    },[memberInfo.payment_method]);

    useEffect(()=>{
        if(consentForm[0] && consentForm[1] && consentForm[2]){
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[1] = true;
            setStepsCanProceed(newStepsCanProceed);
        }else{
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[1] = false;
            setStepsCanProceed(newStepsCanProceed);
        }
    },[consentForm]);

    useEffect(()=>{
        if(memberInfo.waiver_name !== "" && memberInfo.waiver_date !== "" && waiverForm[0] && waiverForm[1] && waiverForm[2] && waiverForm[3]){
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[4] = true;
            setStepsCanProceed(newStepsCanProceed);
        }else{
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[4] = false;
            setStepsCanProceed(newStepsCanProceed);
        }
    },[memberInfo.waiver_name,memberInfo.waiver_date,waiverForm]);
    // APIS  ========================================================================================
    
    async function checkMemberId(params){
        let formData = new FormData();

        formData.append("display_member_id", params.displayMemberId);
    
        const url = "misc/checkMemberId.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setUplineId(params.displayMemberId);
            setUplineName(response.data.member.full_name);
          } 
        } catch (e) {
          console.log(e);
        }
    }
    
    async function getPackages(){
        let formData = new FormData();

        formData.append("display_member_id", params.displayMemberId);
    
        const url = "onboarding/getPackages.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setPackages(response.data.result);
          } 
        } catch (e) {
          console.log(e);
        }
    }

    async function checkEmail(){
        let formData = new FormData();

        formData.append("email", memberInfo.email);
    
        const url = "auth/checkEmail.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setIsNewUser(false);
            let newStepsCanProceed = [...stepsCanProceed];
            newStepsCanProceed[0] = true;
            setStepsCanProceed(newStepsCanProceed);
          } else if(response.data.status === 404){
            setIsNewUser(true);
            props.windowAlert("User with this email not exist!","error");
          } else {
            setIsCheckedEmail(false);
            props.windowAlert(response.data.msg,"error");
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function completeRegistration(waiverSigned){
        let formData = new FormData();
        formData.append("uplineId",uplineId);
        formData.append("uplineName", uplineName);
        formData.append("email",memberInfo.email);
        formData.append("password",memberInfo.password);
        formData.append("fullname",memberInfo.fullname);
        formData.append("gender",memberInfo.gender);
        formData.append("race",memberInfo.race);
        formData.append("dob",memberInfo.dob);
        formData.append("mobile_number",memberInfo.mobile_number);
        formData.append("country",memberInfo.country);
        formData.append("address",memberInfo.address);
        formData.append("bank_name",memberInfo.bank_name);
        formData.append("bank_account",memberInfo.bank_account);
        formData.append("package",memberInfo.package);
        formData.append("payment_method",memberInfo.payment_method);
        formData.append("receipt",memberInfo.receipt);
        formData.append("waiver_name",memberInfo.waiver_name);
        formData.append("waiver_date",memberInfo.waiver_date);
        formData.append("waiver_signed",waiverSigned?"true":"false");

        const url = "onboarding/registerMember.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            props.windowAlert("Member Registration success!","success");
            resetOnboarding();
          }
        } catch (e) {
          console.log(e);
        }
    }
    // FUNCTIONS ========================================================================================
    
    function handleOnChange(val,origin){
        let newMemberInfo = {...memberInfo};
        if(origin === "dob"){

            // Remove any non-digit or hyphen characters
            val = val.replace(/[^0-9-]/g, '');

            // Handle hyphen insertion and removal
            if (val.length <= 4) {
                val = val.replace('-', ''); // Remove hyphen if fewer than 5 characters
            }  else if (val.length === 5 && !val.includes('-')) {
                // Auto-add hyphen when 4 digits are entered without it
                val = val.slice(0, 4) + '-' + val.slice(4);
            }

            // Ensure the input doesn't exceed the YYYY-MM format length
            if (val.length > 7) {
                val = val.slice(0, 7);
            }
            newMemberInfo[origin] = val;
            setMemberInfo(newMemberInfo);
        }else if(origin === "mobile_number"){
            // Remove any non-digit or hyphen characters
            val = val.replace(/[^0-9-]/g, '');
            newMemberInfo[origin] = val;
            setMemberInfo(newMemberInfo);
        }else{
            if(origin === "fullname"){
                newMemberInfo['waiver_name'] = val;
            }
            newMemberInfo[origin] = val;
            setMemberInfo(newMemberInfo);
        }
        if(origin === "email"){
            setIsCheckedEmail(false);
        }
    }
    
    function handleCheckEmail(){
        if(memberInfo.email !== ""){
            if(!isCheckedEmail){
                checkEmail();
                setIsCheckedEmail(true);
            }
        }else{
            props.windowAlert("Email cannot be empty!","error");
        }
    }
    
    function handleConsentChange(check,index){
        let temp = [...consentForm];
        temp[index] = check;
        setConsentForm(temp);
    }

    function handleWaiverChange(check,index){
        let temp = [...waiverForm];
        temp[index] = check;
        setWaiverForm(temp);
    }

    function resetOnboarding(){
        setMemberInfo({email:"",password:"",fullname:"",gender:"male",race:"",dob:"",mobile_number:"",country:"Malaysia",address:"",bank_name:"",bank_account:"",package:"",payment_method:"",receipt:"",waiver_name:"",waiver_date:""});
        setIsCheckedEmail(false);
        setIsViewPassword(false);
        setStepsCanProceed([false,false,false,false,false]);
        setConsentForm([false,false,false]);
        setWaiverForm([false,false,false,false]);
        setActiveStep(0);
    }

    function handleMobileNext(){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    function handleMobileBack(){
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    // RENDER ========================================================================================
    function renderFormHtml(){
        return(
            <div className="max-w-4xl mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-4">Eligibility to Apply as a Member of JDF Rewards Programme and Informed Consent</h1>
                <p className="mb-4">If you are now at least 18 years of age, you are eligible to apply for the JDF Rewards Programme membership using this JDF Rewards Programme membership form.</p>
                <p className="mb-6">If you wish to join as a member of JDF Rewards Programme, you first need to read the following terms and conditions carefully and click acceptance of each box below to agree to them, then fill in the JDF Rewards Programme membership form which will appear as the next step:</p>
                <form className="space-y-4">
                <div>
                    <input type="checkbox" name="terms1" className="mr-2" style={{height:20,width:20}} checked={consentForm[0]} onChange={(e)=>handleConsentChange(e.target.checked,0)}/>
                    <label for="terms1" className="text-lg">I confirm that I have read and I agree to (i) the <a href="https://remai.jdfasia.com/terms-of-use" className="text-blue-500 underline" target="_blank" rel='noopener noreferrer'>Terms of Use</a>, (ii) the <a href="https://remai.jdfasia.com/privacy-policy" className="text-blue-500 underline" target="_blank" rel='noopener noreferrer'>Privacy Policy</a> which sets out how my personal data will be collected, used, disclosed, stored, transferred and/or processed by JDF and the purposes of processing, (iii) <a href="https://remai.jdfasia.com/membership-rules" className="text-blue-500 underline" target="_blank" rel='noopener noreferrer'>JDF Rewards Programme Membership Rules</a>, and (iv) <a href="https://remai.jdfasia.com/code-of-ethics" className="text-blue-500 underline" target="_blank" rel='noopener noreferrer'>Code of Ethics</a>.</label><br />
                </div>
                <div>
                    <input type="checkbox" name="terms2" className="mr-2" style={{height:20,width:20}} checked={consentForm[1]} onChange={(e)=>handleConsentChange(e.target.checked,1)}/>
                    <label for="terms2" className="text-lg">I acknowledge and agree that upon the payment of the fee for the JDF Asia package and the submission of the JDF Rewards Programme membership form, I will become a tentative member of JDF Rewards Programme ("Tentative Membership") after selecting one of the JDF Asia Package Option. I can use the JDF Asia Package immediately online. However, I may cancel my Tentative Membership at any time during the period from the date of payment through Visa/Cash or PayNow or by direct debit transfer and at the end of the 10th day counting from the date of such payment (the "Cooling-Off Period"), excluding Saturdays, Sundays and gazetted federal or national public holidays in the country selected by me in the JDF Rewards Programme membership form. If I do not cancel my Tentative Membership by the last day of the Cooling-Off Period, I agree that my Tentative Membership will be converted automatically to full membership without further notice, and such full membership shall be governed by the Membership Rules and other terms referred to above, whereupon I shall be allowed to log in to the member’s Portal.</label><br />
                </div>
                <div>
                    <input type="checkbox" name="terms3" className="mr-2" style={{height:20,width:20}} checked={consentForm[2]} onChange={(e)=>handleConsentChange(e.target.checked,2)}/>
                    <label for="terms3" className="text-lg">If I wish to use any or all of the JDF Asia package and/or if I wish to initiate a sales presentation to prospective members before the end of the Cooling-Off Period, I shall fill in an agreement to waive or to shorten the Cooling-Off Period form (which can be downloaded here: <a href="https://remai.jdfasia.com/waiver-form" className="text-blue-500 underline" target="_blank" rel='noopener noreferrer'>Waiver Form</a>), and submit the duly completed Waiver Form by email according to the instructions on the Waiver Form. If I do not hear from you within 3 working days from the date of such submission, my submission of the waiver or shortening of the Cooling-Off Period shall be considered accepted by JDF, whereupon I shall be allowed to log in to the members’ Portal before the end of the Cooling-Off Period.</label><br />
                </div>
                </form>
          </div>
          
        )
    }

    function renderWaiverForm(){
        return(
            <div>
                <div>
                    <div className="bg-white rounded-lg">
                        <h1 className="text-xl font-bold mb-4">
                        Waiver Form - Waiver / Shortening of Cooling-Off Period
                        </h1>
                        <p className="text-gray-800 mb-6">
                            <strong>Notes for Completion</strong>
                        <br />
                        1. A Tentative Member of JDF Rewards Programme may waive or shorten
                        his/her cooling-off rights during the Cooling-Off Period (defined in
                        paragraph 3 below).
                        <br />  <br />
                        2. This Waiver Form should be completed and submitted to JDF before or
                        during the start of the Cooling-Off Period, depending on whether the
                        member wishes to waive or shorten the Cooling-Off Period.
                        </p>
                        <form className="space-y-4">
                            <div>
                                <input
                                    type="checkbox"
                                    style={{height:20,width:20}}
                                    checked={waiverForm[0]} 
                                    onChange={(e)=>handleWaiverChange(e.target.checked,0)}
                                />
                                <label   className="ml-2 text-gray-800">
                                    3. I understand that I am entitled to a Cooling-Off Period, defined
                                    as the period starting from the date of payment through Visa/Cash
                                    or PayNow or by direct debit transfer and ends at the end of the
                                    10th day counting from the date of such payment.
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
       
                                    style={{height:20,width:20}}
                                    checked={waiverForm[1]} 
                                    onChange={(e)=>handleWaiverChange(e.target.checked,1)}
                                />
                                <label  className="ml-2 text-gray-800">
                                4. I wish to waive/shorten the Cooling-Off Period which I am entitled
                                to.
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    style={{height:20,width:20}}
                                    checked={waiverForm[2]} 
                                    onChange={(e)=>handleWaiverChange(e.target.checked,2)}
                                />
                                <label  className="ml-2 text-gray-800">
                                5. I agree that I have voluntarily agreed to waive or shorten the
                                Cooling-Off Period I am entitled to, whether under local laws or
                                regulations in the country selected by me or otherwise in the JDF
                                Rewards Programme membership form.
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    style={{height:20,width:20}}
                                    checked={waiverForm[3]} 
                                    onChange={(e)=>handleWaiverChange(e.target.checked,3)}
                                />
                                <label className="ml-2 text-gray-800">
                                6. I agree that I am responsible for making sure that JDF Asia Pte.
                                Ltd. receives this Waiver Form in time and that my submission of
                                this Waiver Form shall be delivered by email or in person to JDF’s
                                office.
                                </label>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="nameId" className="block text-gray-800">
                                Agreed to by:
                                </label>
                                <input
                                    type="text"
                                    id="nameId"
                                    placeholder="Name / ID Number"
                                    className="w-full p-2 border rounded-md"
                                    value={memberInfo.waiver_name}
                                    readOnly
                                   // onChange={(e)=>handleOnChange(e.target.value,"waiver_name")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="date" className="block text-gray-800">
                                Date:
                                </label>
                                <input
                                    type="date"
                                    id="date"
                                    className="w-full p-2 border rounded-md"
                                    value={memberInfo.waiver_date}
                                    onChange={(e)=>handleOnChange(e.target.value,"waiver_date")}
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="sponsorId" className="block text-gray-800">
                                    Direct Sponsor Name / ID Number:
                                </label>
                                <input
                                    type="text"
                                    id="sponsorId"
                                    placeholder="Sponsor Name / ID Number"
                                    className="w-full p-2 border rounded-md"
                                    readOnly
                                    value={`${uplineName} / ${uplineId}`}
                                />
                            </div>
                        </form>
                    </div>
                    </div>
            </div>
        )
    }

    return (
        <div className='container mx-auto w-full'>
            <AppBar position="static">
                <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Member Registration
                </Typography>
                </Toolbar>
            </AppBar>
            <div className='container mx-auto w-full px-5 py-5'>
            <Stepper activeStep={activeStep} sx={{overflowX:"auto"}}>
                <Step>
                    <StepLabel>Check / Setup Account</StepLabel>
                </Step>
                <Step>
                    <StepLabel>JDF Member Eligibility Consent Form</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Package & Member Details</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Payment</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Waiver Form</StepLabel>
                </Step>
            </Stepper>
            {/* <MobileStepper
                variant="text"
                steps={5}
                position="bottom"
                activeStep={activeStep}
                nextButton={
                <Button
                    size="small"
                    onClick={handleMobileNext}
                    disabled={!stepsCanProceed[activeStep]}
                >
                    Next
                    <KeyboardArrowRight />
                </Button>
                }
                backButton={
                <Button size="small" onClick={handleMobileBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                    Back
                </Button>
                }
            /> */}
            {activeStep === 0 && <div className='px-4 py-4'>
                <h6>Your Referrer: <span className='font-semibold text-lg' style={{color:"green"}}>{uplineId}</span></h6>
                <div className='h-3'/>
                <Divider />
                <div className='h-3'/>
                <h6 className='text-lg font-semibold'>Account Checking</h6>
                <FormControl sx={{ m: 1}} fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-email"
                    type='text'
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title="click to check account">
                                <IconButton
                                    onClick={handleCheckEmail}
                                    edge="end"
                                >
                                    {<Search color={isCheckedEmail ? "inherit":"success"}/>}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                    label="Email"
                    value={memberInfo.email}
                    onChange={(e) => handleOnChange(e.target.value,"email")}
                />
                </FormControl>
                {isNewUser && <p className='px-2 italic mb-2'><span className='text-red-700'>*</span>No user account found, please fill in your password to create an account.</p>}
                {isNewUser && <FormControl sx={{ m: 1}} fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type={isViewPassword ? 'text':'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <Tooltip title="Click View/Hide Password">
                                <IconButton
                                    onClick={()=>setIsViewPassword(!isViewPassword)}
                                    edge="end"
                                >
                                    {isViewPassword ? <VisibilityOff /> : <Visibility/>}
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    }
                    label="Password"
                    value={memberInfo.password}
                    onChange={(e) => handleOnChange(e.target.value,"password")}
                />
                </FormControl>}
                <div className='h-5'/>
                <div className='text-center'>
                    <Button disabled={!stepsCanProceed[0]} variant="contained" onClick={()=>{
                        setActiveStep(1);
                    }}>Next</Button>
                </div>
            </div>}
            {activeStep === 1 && <div className='px-4 py-4'>
                {renderFormHtml()}    
                <div className='flex justify-between items-center mt-8'>
                    <Button variant="contained" color="warning" onClick={()=>{
                        setActiveStep(0)
                    }}>Back</Button>
                    <Button disabled={!stepsCanProceed[1]} variant="contained" color="primary" onClick={()=>{
                        setActiveStep(2);
                    }}>Next</Button>
                </div> 
            </div>}
            {activeStep === 2 && <div className='px-4 py-4'>
                <h6 className='text-lg font-semibold'>Select Package</h6>  
                <FormControl fullWidth>
                    <InputLabel>Package</InputLabel>
                    <Select
                        value={memberInfo.package}
                        label="Package"
                        onChange={(e)=>handleOnChange(e.target.value,"package")}
                    >
                        {
                            packages.map((item,index)=>{
                                return (
                                    <MenuItem key={index} value={item.package_id}>{item.package_name} ({item.package_description})</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <div className='h-3'/>
                <Divider />
                <div className='h-3'/>
                <h6 className='text-lg font-semibold'>Fill in your member details</h6>  
                <TextField
                    variant="outlined"
                    required
                    margin='dense'
                    fullWidth
                    label="Fullname"
                    name="fullname"
                    autoComplete="fullname"
                    autoFocus
                    value={memberInfo.fullname}
                    onChange={(e) => handleOnChange(e.target.value,"fullname")}
                />
                <div className='h-2'/>
                <TextField
                    variant="outlined"
                    required
                    margin='dense'
                    fullWidth
                    label="Mobile Number"
                    name="mobile"
                    autoComplete="mobile"
                    autoFocus
                    value={memberInfo.mobile_number}
                    onChange={(e) => handleOnChange(e.target.value,"mobile_number")}
                />
                <div className='h-2'/>
                <TextField
                    variant="outlined"
                    required
                    margin='dense'
                    fullWidth
                    label="Date of Birth"
                    placeholder='YYYY-MM'
                    name="dob"
                    autoComplete="dob"
                    autoFocus
                    value={memberInfo.dob}
                    onChange={(e) => handleOnChange(e.target.value,"dob")}
                />
                <div className='h-3'/>
                <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                        value={memberInfo.gender}
                        label="Gender"
                        onChange={(e) => handleOnChange(e.target.value,"gender")}
                    >
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                    </Select>
                </FormControl>
                <div className='h-3'/>
                <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                        value={memberInfo.country}
                        label="Country"
                        onChange={(e) => handleOnChange(e.target.value,"country")}
                    >
                        <MenuItem value={"Malaysia"}>Malaysia</MenuItem>
                        <MenuItem value={"Singapore"}>Singapore</MenuItem>
                        <MenuItem value={"China"}>China</MenuItem>
                        <MenuItem value={"Brunei"}>Brunei</MenuItem>
                        <MenuItem value={"Cambodia"}>Cambodia</MenuItem>
                        <MenuItem value={"Myanmar"}>Myanmar</MenuItem>
                        <MenuItem value={"Thailand"}>Thailand</MenuItem>
                        <MenuItem value={"Indonesia"}>Indonesia</MenuItem>
                        <MenuItem value={"Vietnam"}>Vietnam</MenuItem>
                        <MenuItem value={"Laos"}>Laos</MenuItem>
                        <MenuItem value={"Philippines"}>Philippines</MenuItem>
                        <MenuItem value={"Japan"}>Japan</MenuItem>
                        <MenuItem value={"Korea"}>Korea</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </FormControl>
                <div className='h-3'/>
                <TextField
                    rows={4}
                    multiline
                    variant="outlined"
                    required
                    margin='dense'
                    fullWidth
                    label="Residential Address"
                    name="address"
                    autoComplete="address"
                    autoFocus
                    value={memberInfo.address}
                    onChange={(e) => handleOnChange(e.target.value,"address")}
                />
                <div className='h-3'/>
                <FormControl fullWidth>
                    <InputLabel>Race</InputLabel>
                    <Select
                        value={memberInfo.race}
                        label="Race"
                        onChange={(e) => handleOnChange(e.target.value,"race")}
                    >
                        <MenuItem value={"Chinese"}>Chinese</MenuItem>
                        <MenuItem value={"Bruneian"}>Bruneian</MenuItem>
                        <MenuItem value={"Burmese"}>Burmese</MenuItem>
                        <MenuItem value={"Malay"}>Malay</MenuItem>
                        <MenuItem value={"Indian"}>Indian</MenuItem>
                        <MenuItem value={"Vietnamese"}>Vietnamese</MenuItem>
                        <MenuItem value={"Japanese"}>Japanese</MenuItem>
                        <MenuItem value={"Korean"}>Korean</MenuItem>
                        <MenuItem value={"Indonesian"}>Indonesian</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                </FormControl>
                <div className='h-3'/>
                <FormControl fullWidth>
                    <InputLabel>Bank</InputLabel>
                    <Select
                        value={memberInfo.bank_name}
                        label="Bank"
                        onChange={(e) => handleOnChange(e.target.value,"bank_name")}
                    >
                        <MenuItem value="Baiduri Bank">Baiduri Bank</MenuItem>
                        <MenuItem value="Bank Islam Brunei Darussalam">Bank Islam Brunei Darussalam</MenuItem>
                        <MenuItem value="CIMB Brunei">CIMB Brunei</MenuItem>
                        <MenuItem value="Maybank">Maybank</MenuItem>
                        <MenuItem value="Perbadanan Tabung Amanah Islam Brunei">Perbadanan Tabung Amanah Islam Brunei</MenuItem>
                        <MenuItem value="RHB Bank">RHB Bank</MenuItem>
                        <MenuItem value="Standard Chartered Bank">Standard Chartered Bank</MenuItem>

                        {/* Cambodia */}
                        <MenuItem value="ABA Bank">ABA Bank</MenuItem>
                        <MenuItem value="ACLEDA Bank">ACLEDA Bank</MenuItem>
                        <MenuItem value="ANZ Royal">ANZ Royal</MenuItem>
                        <MenuItem value="Bank of China Phnom Penh">Bank of China Phnom Penh</MenuItem>
                        <MenuItem value="Campu Bank">Campu Bank</MenuItem>
                        <MenuItem value="Canadia Bank">Canadia Bank</MenuItem>
                        <MenuItem value="FTB (Foreign Trade Bank of Cambodia)">FTB (Foreign Trade Bank of Cambodia)</MenuItem>
                        <MenuItem value="Maybank Cambodia">Maybank Cambodia</MenuItem>
                        <MenuItem value="Union Commercial Bank">Union Commercial Bank</MenuItem>
                        <MenuItem value="Vattanac Bank">Vattanac Bank</MenuItem>

                        {/* China */}
                        <MenuItem value="ABC Bank (Agriculture Bank of China)">ABC Bank (Agriculture Bank of China)</MenuItem>
                        <MenuItem value="Bank of China">Bank of China</MenuItem>
                        <MenuItem value="Bank of Communications">Bank of Communications</MenuItem>
                        <MenuItem value="China Construction Bank">China Construction Bank</MenuItem>
                        <MenuItem value="China Citic Bank Corp">China Citic Bank Corp</MenuItem>
                        <MenuItem value="China Development Bank">China Development Bank</MenuItem>
                        <MenuItem value="China Merchant Bank">China Merchant Bank</MenuItem>
                        <MenuItem value="ICBC (Industrial and Commercial Bank of China)">ICBC (Industrial and Commercial Bank of China)</MenuItem>
                        <MenuItem value="Industrial Bank Co Ltd">Industrial Bank Co Ltd</MenuItem>
                        <MenuItem value="PSBC (Postal Savings Bank of China)">PSBC (Postal Savings Bank of China)</MenuItem>

                        {/* Hong Kong */}
                        <MenuItem value="Bank of China (Hong Kong)">Bank of China (Hong Kong)</MenuItem>
                        <MenuItem value="Citibank Hong Kong">Citibank Hong Kong</MenuItem>
                        <MenuItem value="CITIC Bank International">CITIC Bank International</MenuItem>
                        <MenuItem value="DBS Bank (Hong Kong)">DBS Bank (Hong Kong)</MenuItem>
                        <MenuItem value="Fubon Bank">Fubon Bank</MenuItem>
                        <MenuItem value="Hang Seng Bank">Hang Seng Bank</MenuItem>
                        <MenuItem value="HSBC (Hong Kong and Shanghai Banking Corporation)">HSBC (Hong Kong and Shanghai Banking Corporation)</MenuItem>
                        <MenuItem value="ICBC (Industrial and Commercial Bank of China)">ICBC (Industrial and Commercial Bank of China)</MenuItem>
                        <MenuItem value="Nanyang Commercial Bank">Nanyang Commercial Bank</MenuItem>
                        <MenuItem value="Standard Chartered Bank (Hong Kong)">Standard Chartered Bank (Hong Kong)</MenuItem>
                        <MenuItem value="The Bank of East Asia">The Bank of East Asia</MenuItem>
                        <MenuItem value="Wing Hang Bank">Wing Hang Bank</MenuItem>
                        <MenuItem value="Wing Lung Bank">Wing Lung Bank</MenuItem>

                        {/* Indonesia */}
                        <MenuItem value="Bank Mandiri">Bank Mandiri</MenuItem>
                        <MenuItem value="BRI (Bank Rakyat)">BRI (Bank Rakyat)</MenuItem>
                        <MenuItem value="Bank Central Asia">Bank Central Asia</MenuItem>
                        <MenuItem value="Bank Negara">Bank Negara</MenuItem>
                        <MenuItem value="Bank CIMB Niaga">Bank CIMB Niaga</MenuItem>
                        <MenuItem value="Bank Danamon">Bank Danamon</MenuItem>
                        <MenuItem value="Bank Permata">Bank Permata</MenuItem>
                        <MenuItem value="Panin Bank">Panin Bank</MenuItem>
                        <MenuItem value="Bank Tabungan Negara">Bank Tabungan Negara</MenuItem>
                        <MenuItem value="Maybank (Indonesia)">Maybank (Indonesia)</MenuItem>

                        {/* Japan */}
                        <MenuItem value="Chiba Bank">Chiba Bank</MenuItem>
                        <MenuItem value="Concordia Financial Group">Concordia Financial Group</MenuItem>
                        <MenuItem value="Fukuoka Financial Group">Fukuoka Financial Group</MenuItem>
                        <MenuItem value="Hokuhoku Financial Group">Hokuhoku Financial Group</MenuItem>
                        <MenuItem value="Japan Post Bank">Japan Post Bank</MenuItem>
                        <MenuItem value="Mitsubishi UFJ Financial Group">Mitsubishi UFJ Financial Group</MenuItem>
                        <MenuItem value="Mizuho Financial Group">Mizuho Financial Group</MenuItem>
                        <MenuItem value="Norinchukin Bank">Norinchukin Bank</MenuItem>
                        <MenuItem value="Resona Holdings">Resona Holdings</MenuItem>
                        <MenuItem value="Sumitomo Mitsui Financial Group">Sumitomo Mitsui Financial Group</MenuItem>

                        {/* Laos */}
                        <MenuItem value="ANZ Laos">ANZ Laos</MenuItem>
                        <MenuItem value="Bank of the Lao P.D.R">Bank of the Lao P.D.R</MenuItem>
                        <MenuItem value="BCEL (Banque pour le Commerce Extérieur Lao)">BCEL (Banque pour le Commerce Extérieur Lao)</MenuItem>
                        <MenuItem value="Indochina Bank">Indochina Bank</MenuItem>
                        <MenuItem value="Joint Development Bank">Joint Development Bank</MenuItem>
                        <MenuItem value="Lao Development Bank">Lao Development Bank</MenuItem>
                        <MenuItem value="Lao-Viet Bank">Lao-Viet Bank</MenuItem>
                        <MenuItem value="ST Bank">ST Bank</MenuItem>

                        {/* Macau */}
                        <MenuItem value="Bank of China">Bank of China</MenuItem>
                        <MenuItem value="Banco Comercial De Macau">Banco Comercial De Macau</MenuItem>
                        <MenuItem value="Banco Delta Asia">Banco Delta Asia</MenuItem>
                        <MenuItem value="Banco Nacional Ultramarino">Banco Nacional Ultramarino</MenuItem>
                        <MenuItem value="ICBC Macau">ICBC Macau</MenuItem>
                        <MenuItem value="Luso International Banking">Luso International Banking</MenuItem>
                        <MenuItem value="Macau Chinese Bank">Macau Chinese Bank</MenuItem>
                        <MenuItem value="Novo Banco Asia">Novo Banco Asia</MenuItem>
                        <MenuItem value="OCBC Wing Hang Bank">OCBC Wing Hang Bank</MenuItem>
                        <MenuItem value="Tai Fung Bank">Tai Fung Bank</MenuItem>

                        {/* Malaysia */}
                        <MenuItem value="Affin Bank">Affin Bank</MenuItem>
                        <MenuItem value="Affin Islamic Bank">Affin Islamic Bank</MenuItem>
                        <MenuItem value="Alliance Bank">Alliance Bank</MenuItem>
                        <MenuItem value="AmBank">AmBank</MenuItem>
                        <MenuItem value="BSN">BSN</MenuItem>
                        <MenuItem value="Bank Islam">Bank Islam</MenuItem>
                        <MenuItem value="Bank Rakyat">Bank Rakyat</MenuItem>
                        <MenuItem value="CIMB">CIMB</MenuItem>
                        <MenuItem value="Citibank">Citibank</MenuItem>
                        <MenuItem value="HSBC">HSBC</MenuItem>
                        <MenuItem value="Hong Leong Bank">Hong Leong Bank</MenuItem>
                        <MenuItem value="ICBC">ICBC</MenuItem>
                        <MenuItem value="Maybank">Maybank</MenuItem>
                        <MenuItem value="OCBC">OCBC</MenuItem>
                        <MenuItem value="Public Bank">Public Bank</MenuItem>
                        <MenuItem value="RHB">RHB</MenuItem>
                        <MenuItem value="Standard Chartered">Standard Chartered</MenuItem>
                        <MenuItem value="UOB">UOB</MenuItem>

                        {/* Myanmar */}
                        <MenuItem value="AGD Bank (Asia Green Development Bank)">AGD Bank (Asia Green Development Bank)</MenuItem>
                        <MenuItem value="AYA Bank (Ayeyarwady Bank)">AYA Bank (Ayeyarwady Bank)</MenuItem>
                        <MenuItem value="CB Bank (Co-operative Bank Limited)">CB Bank (Co-operative Bank Limited)</MenuItem>
                        <MenuItem value="KBZ (Kanbawza Bank Limited)">KBZ (Kanbawza Bank Limited)</MenuItem>
                        <MenuItem value="Innwa Bank Limited">Innwa Bank Limited</MenuItem>
                        <MenuItem value="Myanmar Economic Bank">Myanmar Economic Bank</MenuItem>
                        <MenuItem value="MFTB (Myanmar Foreign Trade Bank)">MFTB (Myanmar Foreign Trade Bank)</MenuItem>
                        <MenuItem value="MICB (Myanmar Investment and Commercial Bank)">MICB (Myanmar Investment and Commercial Bank)</MenuItem>
                        <MenuItem value="United Amara Bank">United Amara Bank</MenuItem>
                        <MenuItem value="Yoma Bank">Yoma Bank</MenuItem>

                        {/* Philippines */}
                        <MenuItem value="Bank of the Philippine Islands">Bank of the Philippine Islands</MenuItem>
                        <MenuItem value="BDO Unibank">BDO Unibank</MenuItem>
                        <MenuItem value="China Banking Corporation">China Banking Corporation</MenuItem>
                        <MenuItem value="Development Bank of the Philippines">Development Bank of the Philippines</MenuItem>
                        <MenuItem value="Land Bank of the Philippines">Land Bank of the Philippines</MenuItem>
                        <MenuItem value="Metropolitan Bank & Trust Company">Metropolitan Bank & Trust Company</MenuItem>
                        <MenuItem value="Philippine National Bank">Philippine National Bank</MenuItem>
                        <MenuItem value="RCBC (Rizal Commercial Banking Corporation)">RCBC (Rizal Commercial Banking Corporation)</MenuItem>
                        <MenuItem value="Security Bank Corporation">Security Bank Corporation</MenuItem>
                        <MenuItem value="Union Bank of the Philippines">Union Bank of the Philippines</MenuItem>

                        {/* Singapore */}
                        <MenuItem value="Bank of China">Bank of China</MenuItem>
                        <MenuItem value="Citibank">Citibank</MenuItem>
                        <MenuItem value="Development Bank of Singapore">Development Bank of Singapore</MenuItem>
                        <MenuItem value="Hong Kong and Shanghai Bank Corporation">Hong Kong and Shanghai Bank Corporation</MenuItem>
                        <MenuItem value="Industrial and Commercial Bank of China">Industrial and Commercial Bank of China</MenuItem>
                        <MenuItem value="Maybank">Maybank</MenuItem>
                        <MenuItem value="Overseas Chinese Bank Corporation">Overseas Chinese Bank Corporation</MenuItem>
                        <MenuItem value="Standard Chartered Bank">Standard Chartered Bank</MenuItem>
                        <MenuItem value="United Oversea Bank">United Oversea Bank</MenuItem>

                        {/* South Korea */}
                        <MenuItem value="BNK Financial Group (Busan)">BNK Financial Group (Busan)</MenuItem>
                        <MenuItem value="DGB Financial Group (Daegu-Gyeongbuk)">DGB Financial Group (Daegu-Gyeongbuk)</MenuItem>
                        <MenuItem value="Hana Financial Group">Hana Financial Group</MenuItem>
                        <MenuItem value="Industrial Bank of Korea">Industrial Bank of Korea</MenuItem>
                        <MenuItem value="Kakao Bank">Kakao Bank</MenuItem>
                        <MenuItem value="KB Financial Group">KB Financial Group</MenuItem>
                        <MenuItem value="KB Financial Group (Kookmin Bank)">KB Financial Group (Kookmin Bank)</MenuItem>
                        <MenuItem value="Korea Development Bank">Korea Development Bank</MenuItem>
                        <MenuItem value="NongHyup Financial Group">NongHyup Financial Group</MenuItem>
                        <MenuItem value="Shinhan Financial Group">Shinhan Financial Group</MenuItem>
                        <MenuItem value="Woori Financial Group">Woori Financial Group</MenuItem>

                        {/* Taiwan */}
                        <MenuItem value="Bank of Taiwan">Bank of Taiwan</MenuItem>
                        <MenuItem value="CTBC Bank">CTBC Bank</MenuItem>
                        <MenuItem value="Citibank Taiwan">Citibank Taiwan</MenuItem>
                        <MenuItem value="Deutsche Bank">Deutsche Bank</MenuItem>
                        <MenuItem value="Hua Nan Com Bank">Hua Nan Com Bank</MenuItem>
                        <MenuItem value="Land Bank of Taiwan">Land Bank of Taiwan</MenuItem>
                        <MenuItem value="Shanghai Commercial and Savings Bank">Shanghai Commercial and Savings Bank</MenuItem>
                        <MenuItem value="Sun Commercial Bank">Sun Commercial Bank</MenuItem>
                        <MenuItem value="Taiwan Cooperative Bank">Taiwan Cooperative Bank</MenuItem>
                        <MenuItem value="Taipei Fubon Bank">Taipei Fubon Bank</MenuItem>

                        {/* Thailand */}
                        <MenuItem value="Bangkok Bank">Bangkok Bank</MenuItem>
                        <MenuItem value="Bank of Ayudhya">Bank of Ayudhya</MenuItem>
                        <MenuItem value="Bank of Thailand">Bank of Thailand</MenuItem>
                        <MenuItem value="CIMB (Thai) Bank">CIMB (Thai) Bank</MenuItem>
                        <MenuItem value="Krung Thai Bank">Krung Thai Bank</MenuItem>
                        <MenuItem value="Kasikorn Bank">Kasikorn Bank</MenuItem>
                        <MenuItem value="Siam Commercial Bank">Siam Commercial Bank</MenuItem>
                        <MenuItem value="Siam City Bank">Siam City Bank</MenuItem>
                        <MenuItem value="TMB Bank">TMB Bank</MenuItem>
                        <MenuItem value="UOB Bank">UOB Bank</MenuItem>

                        {/* Vietnam */}
                        <MenuItem value="ACB (Asia Commercial Bank)">ACB (Asia Commercial Bank)</MenuItem>
                        <MenuItem value="Agribank">Agribank</MenuItem>
                        <MenuItem value="BIDV (Bank for Investment and Development of Vietnam)">BIDV (Bank for Investment and Development of Vietnam)</MenuItem>
                        <MenuItem value="Maritime Bank">Maritime Bank</MenuItem>
                        <MenuItem value="Military Bank">Military Bank</MenuItem>
                        <MenuItem value="SHB (Saigon-Hanoi Commercial Joint Stock Bank)">SHB (Saigon-Hanoi Commercial Joint Stock Bank)</MenuItem>
                        <MenuItem value="SCB (Saigon Commercial Bank)">SCB (Saigon Commercial Bank)</MenuItem>
                        <MenuItem value="Techcombank">Techcombank</MenuItem>
                        <MenuItem value="VietinBank">VietinBank</MenuItem>
                        <MenuItem value="Vietcombank">Vietcombank</MenuItem>
                        <MenuItem value="VP Bank">VP Bank</MenuItem>
                    </Select>
                </FormControl>
                <div className='h-2'/>
                <TextField
                    variant="outlined"
                    margin='dense'
                    fullWidth
                    label="Bank Account Number"
                    name="ban"
                    autoComplete="bank_acc_number"
                    autoFocus
                    value={memberInfo.bank_account}
                    onChange={(e) => handleOnChange(e.target.value,"bank_account")}
                />
                <div className='h-5'/>
                <div className='flex justify-between items-center'>
                    <Button variant="contained" color="warning" onClick={()=>{
                        setActiveStep(1)
                    }}>Back</Button>
                    <Button disabled={!stepsCanProceed[2]} variant="contained" onClick={()=>{
                        setActiveStep(3);
                    }}>Next</Button>
                </div> 
            </div>}
            {activeStep === 3 && <div className='px-4 py-4'>
                <h6 className='text-lg font-semibold'>Payment Method</h6>   
                <FormControl fullWidth>
                    <InputLabel>Select Payment Method</InputLabel>
                    <Select
                        value={memberInfo.payment_method}
                        label="Select Payment Method"
                        onChange={(e)=>handleOnChange(e.target.value,"payment_method")}
                    >   
                        <MenuItem value={"bank_transfer"}>Bank Transfer</MenuItem>
                        <MenuItem value={"pay_at_counter"}>Pay at Counter</MenuItem>
                    </Select>
                </FormControl>  
                {
                    memberInfo.payment_method === "bank_transfer" && <div className='mt-4'>
                        <div className='mb-4 mt-4 px-5'>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <h6 className='text-lg font-semibold'>For SG Customer:</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <h2 className="text-xl font-semibold mb-4">Accepted Methods of Payments:</h2>
                                    
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold mb-2">Direct Debit Transfer to our Bank:</h3>
                                        <p className="mb-1">Bank Name: <span className="font-medium">United Overseas Bank Limited</span></p>
                                        <p className="mb-1">Address: <span className="font-medium">80 Raffles Place, #11.00, UOB Plaza One, Singapore 048624</span></p>
                                        <p className="mb-1">Swift Code: <span className="font-medium">UOVBSGSG</span></p>
                                        <p className="mb-1">Bank Code: <span className="font-medium">7375</span></p>
                                        <p className="mb-1">Account No: <span className="font-medium">358-317-843-9</span></p>
                                        <p className="mb-1">In favor of: <span className="font-medium">JDF ASIA PTE. LTD.</span></p>
                                        <p className="mb-1">Transfer Full Amount: <span className="font-medium">SGD (Your Selected Package at Price Indicated)</span></p>
                                    </div>
                                    
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold mb-2">PayNow to our UEN (For Use in Singapore Only)</h3>
                                        <p className="mb-1">Member must have registered PAYNOW with their bank to use this mode of payment.</p>
                                        <p className="mb-1">Enter UEN (Unique Entity Number): <span className="font-medium">202022370K</span></p>
                                        <p className="mb-1">Entity Name: <span className="font-medium">JDF ASIA PTE. LTD.</span> (Name as appears on screen)</p>
                                        <p className="mb-1">Transfer Full Amount: <span className="font-medium">SGD (Your Selected Package at Price Indicated)</span></p>
                                        <p className="mb-1">Bill Reference Number/Payee Reference: <span className="font-medium">"Applicant Name + Mobile Number"</span></p>
                                    </div>
                                    
                                    <div className="mb-6">
                                        <h3 className="text-lg font-semibold">PayNow using our QR code</h3>
                                        <p className="mb-1">If member maintains a PAY NOW account with any of these Banks in Singapore:</p>
                                        <ul className="list-disc ml-6 mb-4">
                                            <li className="mb-1">United Overseas Bank</li>
                                            <li className="mb-1">DBS Bank/POSB</li>
                                            <li className="mb-1">OCBC Bank</li>
                                            <li className="mb-1">Bank of China</li>
                                            <li className="mb-1">ICBC</li>
                                            <li className="mb-1">Maybank</li>
                                            <li className="mb-1">Citibank</li>
                                            <li className="mb-1">Standard Chartered Bank</li>
                                            <li className="mb-1">HSBC</li>
                                        </ul>
                                        <div className='flex justify-center'>
                                            <img src="https://remai.jdfasia.com/images/paynow.jpeg" alt="qr" style={{width:"50%",maxWidth:250}}/>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <h6 className='text-lg font-semibold'>For MY Customer:</h6>  
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='flex justify-center'>
                                        <img src="https://remai.jdfasia.com/images/tng.jpeg" alt="qr" style={{width:"50%",maxWidth:250}}/>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <h6 className='text-lg font-semibold'>Upload Transfer Receipt</h6>  
                        <TextField
                            variant="outlined"
                            required
                            margin='dense'
                            fullWidth
                            autoFocus
                            type='file'
                            onChange={(e)=>{
                                handleOnChange(e.target.files[0],"receipt")
                            }}
                        />
                    </div>
                }
                <div className='flex justify-between items-center mt-8'>
                    <Button variant="contained" color="warning" onClick={()=>{
                        setActiveStep(2)
                    }}>Back</Button>
                    <Button disabled={!stepsCanProceed[3]} variant="contained" color="primary" onClick={()=>{
                        if(memberInfo.payment_method === "bank_transfer"){
                            if(memberInfo.receipt === ""){
                                window.alert("Please upload your bank transfer receipt before moving to next section.")
                            }else{
                                setActiveStep(4);
                            }
                        }else{
                            setActiveStep(4);
                        }
                    }}>Next</Button>
                </div> 
            </div>}
            {activeStep === 4 && <div className='px-4 py-4'>
                {renderWaiverForm()}
                <div className='flex justify-between items-center mt-8 flex-wrap'>
                    <div className='mb-2'>
                        <Button variant="contained" color="warning" onClick={()=>{
                            setActiveStep(3)
                        }}>Back</Button>
                    </div>
                    <div className='flex justify-between items-center'>
                        {!stepsCanProceed[4] && <Button variant="contained" color="error" onClick={()=>{
                            let resp = window.confirm("Are you sure to complete this registration without waiver?");
                            if(resp){
                                completeRegistration(false);
                            }
                        }}>Complete Registration Without Waiver</Button>}
                        <div style={{width:15}}></div>
                        {stepsCanProceed[4] && <Button disabled={!stepsCanProceed[4]} variant="contained" color="success" onClick={()=>{
                            let resp = window.confirm("Are you sure to complete this registration?");
                            if(resp){
                                completeRegistration(true);
                            }
                        }}>Complete Registration</Button>}
                    </div>
                </div> 
            </div>}
            </div>
        </div>
    )
}

export default SignupPage;