import React, { useEffect, useState} from 'react';
import Screen from '../shared/screen';
import { Divider } from '@mui/material';
import { jdfInstance } from '../../http/http';
import { numberWithCommas } from '../shared/util';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function StatementsPage(){
    const [statements,setStatements] = useState([]);
    const [tableRows,setTableRows] = useState([]);
    const [dtccTableRows,setDtccTableRows] = useState([]);
    const [cashTableRows,setCashTableRows] = useState([]);
    const [profile,setProfile] = useState({members:[],email:""});
    const [loading,setLoading] = useState(false);
    const [currentSelectedMember,setCurrentSelectedMember] = useState({display_member_id:"",member_id:"",dt_balance:0});
    const [currentSelectedProduct,setCurrentSelectedProduct] = useState({});
    const [showProductModal,setShowProductModal] = useState(false);
    const [tiersSummary,setTiersSummary] = useState([]);
    const [tiersTotal,setTiersTotal] = useState({total_member:0,total_cash:0,total_dt:0});
    useEffect(()=>{
        import('bootstrap/dist/css/bootstrap.min.css');
        getUserProfile();
    },[]);

    useEffect(()=>{
        getProductTransactions();
    },[currentSelectedMember]);
    // APIS  ========================================================================================

     async function getProductTransactions(){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        const url = "accounts/getStatements.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setStatements(response.data.result);
            prepareDtRows(response.data.result.dt_transactions);
            prepareDtccRows(response.data.result.dtcc_transactions);
            prepareCashRows(response.data.result.cash_transactions);
            prepareTiersSummary(response.data.result.tiers_summary);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setProfile(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }
    // FUNCTIONS ========================================================================================

        function prepareDtRows(rows){
            let mapped = rows.map((row, index) => {
                return {
                    ...row,
                    id:index,
                    no:index+1,
                    actions: row
                };
            });
            setTableRows(mapped);
        }

        function prepareDtccRows(rows){
            let mapped = rows.map((row, index) => {
                return {
                    ...row,
                    id:index,
                    no:index+1,
                    actions: row
                };
            });
            setDtccTableRows(mapped);
        }

        function prepareCashRows(rows){
            let mapped = rows.map((row, index) => {
                return {
                    ...row,
                    id:index,
                    no:index+1,
                    actions: row
                };
            });
            setCashTableRows(mapped);
        }

        function handleSelectChange(e){
            let val = e.target.value;
            let filtered = profile.members.filter((item)=>item.member_id === val);
            if(filtered.length > 0){
                setCurrentSelectedMember(filtered[0]);
            }else{
                setCurrentSelectedMember({display_member_id:"",member_id:"",dt_balance:0});
            }
        }

        function prepareTiersSummary(tiers){
            let keys = Object.keys(tiers);
            let processedMapped = [];
            let tiersTotal = {total_member:0,total_cash:0,total_dt:0};
            keys.forEach((key,index)=>{
                tiersTotal.total_member += Number(tiers[key].member_count);
                tiersTotal.total_cash += Number(tiers[key].cash_reward);
                tiersTotal.total_dt += Number(tiers[key].dt_reward);
                tiers[key]['tier'] = key;
                processedMapped.push(tiers[key]);
            });
            setTiersTotal(tiersTotal);
            setTiersSummary(processedMapped);
        }
    // RENDER ========================================================================================

        return (
            <Screen pageSlug="statements">
                <div className="container-fluid mx-auto px-4 py-4">
                    <div className='mb-2'>
                        <label className="font-bold text-lg block mb-2">
                            Select Member: 
                        </label>
    
                        <select value={currentSelectedMember.member_id} onChange={handleSelectChange} className="min-w-80 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none">
                            <option value="">Select Member</option>
                            {profile.members.map((member,index)=>{
                                return (
                                    <option key={index} value={member.member_id}>
                                        {member.member_name} ({member.display_member_id}) (DT: {numberWithCommas(member.dt_balance)})
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Divider/>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Cash Statements
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ height: '60vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                                    <DataGrid 
                                        rows={cashTableRows} 
                                        columns={
                                            [
                                                { field: 'no', headerName: 'No', width: 100 },
                                                { field: 'amount', headerName: 'SGD Amount', width: 150 },
                                                { field: 'description', headerName: 'Description', width: 350 },
                                                { field: 'type', headerName: 'Transaction Type', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'status', headerName: 'Status', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'reg_date', headerName: 'Transaction Date', width: 170 },
                                            ]
                                        } 
                                        getDetailPanelHeight={() => 'auto'}
                                        pagination
                                        autoPageSize
                                        loading={loading} 
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                            showQuickFilter: true,
                                            },
                                            loadingOverlay: {
                                                variant: 'linear-progress',
                                                noRowsVariant: 'linear-progress',
                                            },
                                        }}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                DTCC Statements
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ height: '60vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                                    <DataGrid 
                                        rows={dtccTableRows} 
                                        columns={
                                            [
                                                { field: 'no', headerName: 'No', width: 100 },
                                                { field: 'booking_code', headerName: 'Transaction ID', width: 200 },
                                                { field: 'amount', headerName: 'DTCC Amount', width: 150 },
                                                { field: 'description', headerName: 'Description', width: 350 },
                                                { field: 'status', headerName: 'Status', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'reg_date', headerName: 'Transaction Date', width: 170 },
                                            ]
                                        } 
                                        getDetailPanelHeight={() => 'auto'}
                                        pagination
                                        autoPageSize
                                        loading={loading} 
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                            showQuickFilter: true,
                                            },
                                            loadingOverlay: {
                                                variant: 'linear-progress',
                                                noRowsVariant: 'linear-progress',
                                            },
                                        }}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                DT Statements
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ height: '60vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                                    <DataGrid 
                                        rows={tableRows} 
                                        columns={
                                            [
                                                { field: 'no', headerName: 'No', width: 100 },
                                                { field: 'amount', headerName: 'DT Amount', width: 150 },
                                                { field: 'description', headerName: 'Description', width: 350 },
                                                { field: 'type', headerName: 'Transaction Type', width: 150, valueFormatter:((value)=> value.toUpperCase()) },                                                { field: 'expiry_date', headerName: 'Expiry Date', width: 150 },
                                                { field: 'reg_date', headerName: 'Transaction Date', width: 170 },
                                            ]
                                        } 
                                        getDetailPanelHeight={() => 'auto'}
                                        pagination
                                        autoPageSize
                                        loading={loading} 
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                            showQuickFilter: true,
                                            },
                                            loadingOverlay: {
                                                variant: 'linear-progress',
                                                noRowsVariant: 'linear-progress',
                                            },
                                        }}
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                15 Levels Summary
                            </AccordionSummary>
                            <AccordionDetails>
                                <table class="table table-bordered" width="100%" cellspacing="0">
                                    <thead>
                                    <tr>
                                        <th>Member Count</th>
                                        <th>Level</th>
                                        <th>Total Cash Reward</th>
                                        <th>Total DT Reward</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tiersSummary.map((item,index)=>{
                                                return(
                                                    <tr key={index}>
                                                        <td>{item.member_count}</td>
                                                        <td>{item.tier}</td>
                                                        <td>{numberWithCommas(item.cash_reward)}</td>
                                                        <td>{numberWithCommas(item.dt_reward)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                       
                                    <tr style={{borderTop:"solid 3px black",borderBottom:"solid 3px black"}}>
                                        <td>{numberWithCommas(tiersTotal.total_member)}</td>
                                        <td>-</td>
                                        <td>{numberWithCommas(tiersTotal.total_cash)}</td>
                                        <td>{numberWithCommas(tiersTotal.total_dt)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </AccordionDetails>
                        </Accordion>
                </div>
            </Screen>
        );
}

export default StatementsPage;