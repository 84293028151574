import React, { useEffect, useState} from 'react';
import Screen from '../shared/screen';
import { Divider } from '@mui/material';
import { jdfInstance } from '../../http/http';
import { numberWithCommas } from '../shared/util';
function ProductPage(props){
    
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const [product,setProduct] = useState({product_name:"",product_description:"",product_image:"",cf_amount:"",remainingQty:0});
    const [profile,setProfile] = useState({members:[],email:""});
    const [quantity,setQuantity] = useState(0);
    const [currentSelectedMember,setCurrentSelectedMember] = useState({display_member_id:"",member_id:"",dt_balance:0});

    useEffect(()=>{
        checkIsLogin();
        getProduct();
        getUserProfile();
    },[props.productId]);

    // APIS  ========================================================================================

     async function getProduct(){
        let formData = new FormData();
        formData.append("product_id",props.productId);

        const url = "products/getProduct.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            setProduct(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            setProfile(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function purchaseProduct(){
        let formData = new FormData();
        formData.append("product_id",props.productId);
        formData.append("quantity",quantity);
        formData.append("member_id",currentSelectedMember.member_id);

        const url = "products/redeemProduct.php";
        try {
          const response = await jdfInstance.post(url, formData);
          window.alert(response.data.msg);
          if (response.data.status === 201) {
            getUserProfile();
            getProduct();
            setCurrentSelectedMember({display_member_id:"",member_id:"",dt_balance:0});
            setQuantity(0);
          }
        } catch (e) {
          console.log(e);
        }
    }
    // FUNCTIONS ========================================================================================
    function checkIsLogin(){
        if(localStorage.getItem("session")){
            setIsLoggedIn(true);
        }else{
            setIsLoggedIn(false);
        }
    }

    function handlePurchase(){
        let resp = window.confirm(`Are you sure to redeem ${quantity} unit of ${product.product_name}?`);
        if(resp){
            purchaseProduct();
        }
    }

    function handleSelectChange(e){
        let val = e.target.value;
        let filtered = profile.members.filter((item)=>item.member_id === val);
        if(filtered.length > 0){
            setCurrentSelectedMember(filtered[0]);
        }else{
            setCurrentSelectedMember({display_member_id:"",member_id:"",dt_balance:0});
        }
    }
    // RENDER ========================================================================================


    return (
        <div className="container mx-auto px-4 py-8">
            {/* Product Display Section */}
            <div className="flex flex-col md:flex-row justify-center items-center gap-8">
                {/* Product Image */}
                <div className="w-full md:w-1/2 mb-4">
                    <img
                        src={product.product_image}
                        alt={product.product_name}
                        className="w-full rounded-lg shadow-lg"
                    />
                </div>

                {/* Product Info & Purchase Form */}
                <div className="w-full md:w-1/2">
                    <form onSubmit={(e) => e.preventDefault()} className="space-y-4" noValidate>
                        {/* Product Name */}
                        <h4 className="text-xl font-bold">{product.product_name}</h4>

                        {/* CF Amount */}
                        <h4 className="text-lg font-bold text-cyan-500">
                            DT {product.cf_amount}
                        </h4>

                        {/* Product Description */}
                        <div>
                            <label className="font-bold">Description:</label>
                            <p className="whitespace-pre-wrap mt-2">{product.product_description}</p>
                        </div>

                        {/* Quantity Remaining */}
                        <div>
                            <label className="font-bold text-lg">
                                Quantity Remaining: 
                                <span className="ml-1">{product.remainingQty}</span>
                            </label>
                        </div>

                        <Divider />
                        <div >
                            <label className="font-bold text-lg block mb-2">
                                Choose Member: 
                            </label>
                            <select value={currentSelectedMember.member_id} onChange={handleSelectChange} className="min-w-80 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none">
                                <option value="">Select Member</option>
                                {profile.members.map((member,index)=>{
                                    return (
                                        <option key={index} value={member.member_id}>
                                            {member.member_name} ({member.display_member_id}) (DT: {numberWithCommas(member.dt_balance)})
                                        </option>
                                    );
                                })}
                            </select>
                        </div>


                        {/* CF Total */}
                        <div>
                            <label className="font-bold text-lg">
                                DT Total: 
                                <span className="text-cyan-500 ml-1">
                                    {numberWithCommas(product.cf_amount * quantity)}
                                </span>
                            </label>
                        </div>

                        {/* Quantity Input */}
                        <div className="mt-2">
                            <label className="font-bold">Quantity: </label>
                            <input
                                type="number"
                                className="form-input border border-gray-300 p-2 rounded w-24"
                                value={quantity}
                                min="1"
                                max={product.remainingQty}
                                onChange={(e) => setQuantity(e.target.value)}
                            />
                        </div>

                        <Divider />

                        {/* Purchase Button Logic */}
                        {isLoggedIn && Number(currentSelectedMember.dt_balance) >= Number(product.cf_amount) && Number(product.remainingQty) > 0 ? (
                            <button
                                onClick={handlePurchase}
                                disabled={quantity === 0}
                                className={quantity === 0 ? "w-full bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600":"w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"}
                            >
                                Redeem Now
                            </button>
                        ) : Number(product.remainingQty) <= 0 ? (
                            <p className="text-red-500 font-semibold">* No stock available at the moment.</p>
                        ) : isLoggedIn ? (
                            <p className="text-red-500 font-semibold">* You don't have enough DT to redeem this product. </p>
                        ) : (
                            <a
                                href={`mailto:jdfasia@gmail.com?subject=Enquiry on ${product.product_name}`}
                                className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 inline-block text-center"
                            >
                                Contact Us
                            </a>
                        )}
                        <button
                            onClick={props.handleBack}
                            className="w-full bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
                        >
                            Back
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ProductPage;