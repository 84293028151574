import React, { useEffect, useState} from 'react'
import Screen from '../shared/screen';
import { jdfInstance } from '../../http/http';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRCode from "react-qr-code";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Edit from '@mui/icons-material/Edit';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Tooltip,IconButton } from '@mui/material';
import Warning from '@mui/icons-material/Warning';
import Check from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import Visibility from '@mui/icons-material/Visibility';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { numberWithCommas } from '../shared/util';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';

function HomePage(props) {
  
    const [profile,setProfile] = useState({members:[],email:""});
    const [currentSelectedMember, setCurrentSelectedMember] = useState({display_member_id:""});
    const [showProfileModal,setShowProfileModal] = useState(false);
    const [tableRows,setTableRows] = useState([]);
    const [loading,setLoading] = useState(true);


    useEffect(()=>{
        getUserProfile();
        import('bootstrap/dist/css/bootstrap.min.css');
    },[]);

    // APIS  ========================================================================================

    async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            setProfile(response.data.result);
            prepareTableRows(response.data.result.members);
          } else {
            window.alert(response.data.msg);
          }
          setLoading(false);
        } catch (e) {
          console.log(e);
        }
    }

    async function updateUserProfile(){
        let formData = new FormData();
        formData.append("bank_name",currentSelectedMember.bank_name);
        formData.append("bank_account",currentSelectedMember.bank_account);
        formData.append("member_id",currentSelectedMember.member_id);
        const url = "accounts/updateProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          console.log(response.data);
          if (response.data.status === 201) {
            handleModalClose();
            props.windowAlert("Profile updated!","success");
            getUserProfile();
          } else {
            window.alert(response.data.msg);
          }
          setLoading(false);
        } catch (e) {
          console.log(e);
        }
    }

    // FUNCTIONS ========================================================================================

    function handleModalClose(){
        setShowProfileModal(false);
        setCurrentSelectedMember({});
    }

    function handleSubmitEdit(e){
        e.preventDefault();
        updateUserProfile();
    }

    function handleOnChange(val,origin){
        let memberDetails = {...currentSelectedMember};
        memberDetails[origin] = val;
        setCurrentSelectedMember(memberDetails);
    }   

    function prepareTableRows(rows){
        let mapped = rows.map((row, index) => {
            return {
                ...row,
                id:index,
                no:index+1,
                actions: row
            };
        });
        setTableRows(mapped);
    }

    // RENDER ========================================================================================
    function renderStatusDisplay(statusDisplay){
        switch(statusDisplay){
            case "INACTIVE": return <strong style={{color:"#ed6c02"}}>{statusDisplay}</strong>;
            case "BOSS": return <strong style={{color:"#2196f3"}}>{statusDisplay}</strong>
            case "ACTIVE": return <strong style={{color:"#4caf50"}}>{statusDisplay}</strong>
            default: return <strong >{statusDisplay}</strong>
        }
    }

    function renderListView(){
        return(
            <div>
                 <h6>Your linked members:</h6>
                {
                    profile.members.map((profile,index)=>{
                        return(
                            <Accordion key={index}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    id={`panel${index}-header`}
                                >
                                    {profile.member_name} ({profile.display_member_id}),  Cash: SGD <strong className='ms-2'>{profile.cash_balance}</strong>, DT: <strong className='ms-2'>{profile.dt_balance}</strong>
                                    <div className='ml-2'>
                                        {profile.status === "inactive" && <Tooltip title="Account is inactive"><Warning color="warning"/></Tooltip>}
                                        {profile.status === "active" && <Tooltip title="Account is active"><Check color="success"/></Tooltip>}
                                        {profile.status === "boss" && <Tooltip title="Account is Boss"><StarIcon color="primary"/></Tooltip>}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='container mx-auto'>
                                        {profile.status !== "inactive" && <div>
                                            <div className='flex justify-items-center w-full mb-3'>
                                                <div className='max-w-40 mx-auto'>
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "50%", margin:"0 auto" }}
                                                        value={`https://remai.jdfasia.com/register/${profile.display_member_id}`}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                    <div className='text-center mt-3'>
                                                        <Button variant='contained' color='primary' onClick={()=>{
                                                            let url = `https://remai.jdfasia.com/register/${profile.display_member_id}`;
                                                            window.navigator.clipboard.writeText(url);
                                                            props.windowAlert("Referral link copied!","success");
                                                        }}>Copy Link</Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />
                                        </div>}
                                        <div className='mx-auto px-2 py-2'>
                                            <h5 className='font-semibold text-lg'>Member Details</h5>
                                            <p><strong>Cash:</strong> <strong>SGD {profile.cash_balance}</strong></p>
                                            <p><strong>DT:</strong> <strong>{profile.dt_balance}</strong></p>
                                                    
                                            <p><strong>Sponsored By:</strong> <strong>{profile.upline_display_id}</strong></p>
                                            <p><strong>Status:</strong> {renderStatusDisplay(profile.status_display)}</p>
                                            <p><strong>Package:</strong> {profile.package_details.package_name} ({profile.package_details.package_description})</p>
                                            <p><strong>PV:</strong> {profile.pv}</p>
                                            <p><strong>Gender:</strong> {profile.gender.toUpperCase()}</p>
                                            <p><strong>Race:</strong> {profile.race.toUpperCase()}</p>
                                            <p><strong>Mobile:</strong> {profile.mobile_number}</p>
                                            <p><strong>Address:</strong> {profile.address}</p>
                                            <p><strong>Country:</strong> {profile.country}</p>
                                            <p><strong>Bank:</strong> {profile.bank_name === ""?"-":profile.bank_name}</p>
                                            <p><strong>Bank Account:</strong> {profile.bank_account === ""?"-":profile.bank_account}</p>
                                            <div className='text-center'>
                                                <Button variant="contained" color="info" startIcon={<Edit />} onClick={()=>{
                                                    //show modal
                                                    setCurrentSelectedMember(profile);
                                                    setShowProfileModal(true);
                                                }}>Edit Detail</Button>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                }
            </div>
        )
    }

    function renderActionsCell(innerProps){
        let profile = innerProps.value;
        return(
            <div>
                <Tooltip title="View / Edit Details">
                    <IconButton  color="primary" onClick={()=>{
                        //show modal
                        setCurrentSelectedMember(profile);
                        setShowProfileModal(true);
                    }}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy Referral Link">
                    <IconButton  color="warning" onClick={()=>{
                        let url = `https://remai.jdfasia.com/register/${profile.display_member_id}`;
                        window.navigator.clipboard.writeText(url);
                        props.windowAlert("Referral link copied!","success");
                    }}>
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    function renderProfileModal(){
        let profile = currentSelectedMember;
        return(
            <Modal centered size="lg" show={showProfileModal} onHide={handleModalClose} style={{zIndex:1201}}>
                <Modal.Header closeButton>
                <Modal.Title>{currentSelectedMember.member_name} {currentSelectedMember.display_member_id}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full">
                        {profile.status !== "inactive" && <div>
                            <div className='flex justify-items-center w-full mb-3'>
                                <div className='max-w-40 mx-auto'>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "50%", margin:"0 auto" }}
                                        value={`https://remai.jdfasia.com/register/${profile.display_member_id}`}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <div className='text-center mt-3'>
                                        <Button variant='contained' color='primary' onClick={()=>{
                                            let url = `https://remai.jdfasia.com/register/${profile.display_member_id}`;
                                            window.navigator.clipboard.writeText(url);
                                            props.windowAlert("Referral link copied!","success");
                                        }}>Copy Link</Button>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                        </div>}
                    <h5 className='font-semibold text-lg'>Member Details</h5>
                    <p><strong>Cash:</strong> <strong>SGD {profile.cash_balance}</strong></p>
                    <p><strong>DT:</strong> <strong>{profile.dt_balance}</strong></p>
                            
                    <p><strong>Sponsored By:</strong> <strong>{profile.upline_display_id}</strong></p>
                    <p><strong>Status:</strong> {renderStatusDisplay(profile.status_display)}</p>
                    <p><strong>Package:</strong> {profile.package_details?.package_name} ({profile.package_details?.package_description})</p>
                    <p><strong>PV:</strong> {profile.pv}</p>
                    <p><strong>Gender:</strong> {profile.gender?.toUpperCase()}</p>
                    <p><strong>Race:</strong> {profile.race?.toUpperCase()}</p>
                    <p><strong>Mobile:</strong> {profile.mobile_number}</p>
                    <p><strong>Address:</strong> {profile.address}</p>
                    <p><strong>Country:</strong> {profile.country}</p>
                    <hr />
                    <div className='form-group mb-2'>
                        <label>Bank Name</label>
                        <input type="text" className='form-control' value={profile.bank_name} onChange={(e)=>{
                            setCurrentSelectedMember({...currentSelectedMember,bank_name:e.target.value});
                        }}/>
                    </div>
                    <div className='form-group'>
                        <label>Bank Account</label>
                        <input type="text" className='form-control' value={profile.bank_account} onChange={(e)=>{
                            setCurrentSelectedMember({...currentSelectedMember,bank_account:e.target.value});
                        }}/>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="primary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="contained" color="success" onClick={handleSubmitEdit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function renderTableView(){
        return(
            <div style={{ height: '80vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                <DataGrid 
                    rows={tableRows} 
                    columns={
                        [
                            { field: 'display_member_id', headerName: 'Member ID', width: 100 },
                            { field: 'total_cash_earned', headerName: 'Total Cash Earned', width: 200 , valueFormatter:((value)=> `SGD ${numberWithCommas(value)}`) },
                            { field: 'total_cash_paid', headerName: 'Total Cash Paid', width: 150 , valueFormatter:((value)=> `SGD ${numberWithCommas(value)}`) },
                            { field: 'total_cash_balance', headerName: 'Total Cash Balance', width: 150, valueFormatter:((value)=> `SGD ${numberWithCommas(value)}`)  },
                            { field: 'total_dt_earned', headerName: 'Total DT Earned', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                            { field: 'total_dt_redeemed', headerName: 'Total DT Redeemed', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                            { field: 'dt_balance', headerName: 'Total DT Balance', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                            { field: 'status', headerName: 'Status', width: 150, valueFormatter:((value)=> value === "active" ? "MEMBER":value.toUpperCase()) },
                            { field: 'actions', headerName: 'Actions', width: 150, renderCell: renderActionsCell },
                        ]
                    } 
                    getDetailPanelHeight={() => 'auto'}
                    pagination
                    autoPageSize
                    loading={loading} 
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                        showQuickFilter: true,
                        },
                        loadingOverlay: {
                            variant: 'linear-progress',
                            noRowsVariant: 'linear-progress',
                        },
                    }}
                />
            </div>
        )
    }

    return (
        <Screen pageSlug="home">
            <div className='container mx-auto px-4 py-4'>
                <h3 className='text-xl font-semibold'>Welcome back, {profile.email}</h3>
                <br/>
                {/* {renderListView()} */}
                {renderTableView()}
            </div>
            {renderProfileModal()}
        </Screen>
    );
}

export default HomePage;