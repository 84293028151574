import React, {useState,useEffect} from "react";
import Screen from '../shared/screen';
import { Divider } from '@mui/material';
import { jdfInstance } from '../../http/http';
import { numberWithCommas } from '../shared/util';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Visibility from '@mui/icons-material/Visibility';
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import IconButton from '@mui/material/IconButton';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from "@mui/material/Tooltip";

function MyProductsPage(props) {
    const [myProducts,setMyProducts] = useState({my_products:[],my_sales:[],my_quota:0});
    const [tabIndex, setTabIndex] = useState(0);
    const [productDetail,setProductDetail] = useState({product_image:"",product_name:"",product_description:"",cf_amount:"",quantity:"",product_status:"Unpublished"});
    const [myProductTable,setMyProductTable] = useState([]);
    const [mySalesTable,setMySalesTable] = useState([]);
    const [profile,setProfile] = useState({members:[],email:""});
    const [loading,setLoading] = useState(false);
    const [currentSelectedMember,setCurrentSelectedMember] = useState({display_member_id:"",member_id:"",dt_balance:0});
    const [showProductModal,setShowProductModal] = useState(false);
    const [showTxModal,setShowTxModal] = useState(false);
    const [txDetail,setTxDetail] = useState({});

    useEffect(()=>{
        getUserProfile();
        import('bootstrap/dist/css/bootstrap.min.css');
    },[]);

    useEffect(()=>{
        getProducts();
    },[currentSelectedMember]);

    // APIS  ========================================================================================

    async function getProducts(){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        const url = "accounts/getMyProducts.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setMyProducts(response.data.result);
            prepareMyProductTableRows(response.data.result.my_products);
            prepareMySalesTableRows(response.data.result.my_sales);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setProfile(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function uploadProduct(){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        formData.append("product_name",productDetail.product_name);
        formData.append("product_description",productDetail.product_description);
        formData.append("cf_amount",productDetail.cf_amount);
        formData.append("quantity",productDetail.quantity);
        formData.append("product_image",productDetail.product_image);

        const url = "accounts/uploadProduct.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            props.windowAlert("Product added successfully. Now pending approval from management.");
            setProductDetail({product_image:"",product_name:"",product_description:"",cf_amount:"",quantity:"",product_status:"Unpublished"});
            getProducts();
          } else {
            props.windowAlert(response.data.msg,"error");
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function updateProduct(productId){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        formData.append("product_id",productId);
        formData.append("product_name",productDetail.product_name);
        formData.append("product_description",productDetail.product_description);
        formData.append("cf_amount",productDetail.cf_amount);
        formData.append("quantity",productDetail.quantity);
        formData.append("product_image",productDetail.product_image);
        formData.append("product_status",productDetail.product_status);

        const url = "accounts/updateProduct.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            props.windowAlert("Product updated successfully");
            setProductDetail({product_image:"",product_name:"",product_description:"",cf_amount:"",quantity:"",product_status:"Unpublished"});
            setShowProductModal(false);
            getProducts();
          } else {
            props.windowAlert(response.data.msg,"error");
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function deleteProduct(product){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        formData.append("product_id",product.id);
    
        const url = "accounts/deleteProduct.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            props.windowAlert("Product deleted!");
          } else {
            props.windowAlert(response.data.msg,"error");
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function updateSalesStatus(){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        formData.append("tx_id",txDetail.id);
        formData.append("status",txDetail.status);

        const url = "accounts/updateTxStatus.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            props.windowAlert("Product Transaction Status Updated!");
            setTxDetail({});
            setShowTxModal(false);
            getProducts();
          } else {
            props.windowAlert(response.data.msg,"error");
          }
        } catch (e) {
          console.log(e);
        }
    }
    // FUNCTIONS ========================================================================================

    function handleTabChange(e,index){
        setTabIndex(index);
    }


    function prepareMyProductTableRows(rows){
        let mapped = rows.map((row, index) => {
            return {
                ...row,
                id:index,
                no:index+1,
                actions: row
            };
        });
        setMyProductTable(mapped);
    }

    function prepareMySalesTableRows(rows){
        let mapped = rows.map((row, index) => {
            return {
                ...row,
                id:index,
                no:index+1,
                actions: row
            };
        });
        setMySalesTable(mapped);
    }

        function handleSelectChange(e){
            let val = e.target.value;
            let filtered = profile.members.filter((item)=>item.member_id === val);
            if(filtered.length > 0){
                setCurrentSelectedMember(filtered[0]);
            }else{
                setCurrentSelectedMember({display_member_id:"",member_id:"",dt_balance:0});
            }
        }

    function handleSubmitAddProduct(){
        if(productDetail.product_name === ""){
            props.windowAlert("Please enter product name","error");
        }else if(productDetail.product_description === ""){
            props.windowAlert("Please enter product description","error");
        }else if(productDetail.cf_amount === ""){
            props.windowAlert("Please enter DT amount","error");
        }else if(productDetail.quantity === ""){
            props.windowAlert("Please enter product quantity","error"); 
        }else if(productDetail.product_image === ""){   
            props.windowAlert("Please choose a product image","error"); 
        }else{
            uploadProduct();
        }
    }

    function handleSubmitUpdateProduct(){
        if(productDetail.product_name === ""){
            props.windowAlert("Please enter product name","error");
        }else if(productDetail.product_description === ""){
            props.windowAlert("Please enter product description","error");
        }else if(productDetail.cf_amount === ""){
            props.windowAlert("Please enter DT amount","error");
        }else if(productDetail.quantity === ""){
            props.windowAlert("Please enter product quantity","error"); 
        }else if(productDetail.product_image === ""){   
            props.windowAlert("Please choose a product image","error"); 
        }else{
            updateProduct(productDetail.id);
        }
    }

    function handleModalClose(){
        setProductDetail({product_image:"",product_name:"",product_description:"",cf_amount:"",quantity:"",product_status:"Unpublished"});
        setShowTxModal(false);
        setShowProductModal(false);
    }

    function handleTxModalClose(){
        setTxDetail({});
        setShowTxModal(false);
    }
    // RENDER ========================================================================================
    function renderActionsCell(props){
        return(
            <div>
                <Tooltip title="Edit Product">
                    <IconButton  color="primary" onClick={()=>{
                        //show modal
                        setProductDetail(props.value);
                        setShowProductModal(true);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Product">
                <IconButton  color="error" onClick={()=>{
                    let resp = window.confirm("Are you sure to delete this product?");
                    if(resp){
                        deleteProduct(props.value);
                    }                    
                }}>
                    <Delete />
                </IconButton>
                </Tooltip>
            </div>
        );
    }

    function renderSalesActionsCell(props){
        return(
            <div>
                <Tooltip title="View Detail">
                    <IconButton  color="primary" onClick={()=>{
                        //show modal
                        setTxDetail(props.value);
                        setShowTxModal(true);
                    }}>
                        <Visibility />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }

    function renderModal(){
        return(
            <Modal centered size="lg" enforceFocus={false} show={showProductModal} onHide={handleModalClose} style={{zIndex:1201}}>
                <Modal.Header closeButton>
                <Modal.Title>Edit {productDetail.product_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full md:w-1/4 mb-4" style={{margin:"0 auto"}}>
                        <img
                            src={productDetail.product_image}
                            alt={productDetail.product_name}
                            className="w-full rounded-lg shadow-lg"
                        />
                    </div>
                    <div className="w-full">
                        <div class="form-group mb-3">
                            <label>Product Approval Status: <strong>{productDetail.product_approval}</strong></label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Product Image<span className="mandatory">*</span></label>
                            <input type="file" className="form-control" onChange={(e)=>setProductDetail({...productDetail,product_image:e.target.files[0]})} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Product Name<span className="mandatory">*</span></label>
                            <input type="text" className="form-control" value={productDetail.product_name} onChange={(e)=>setProductDetail({...productDetail,product_name:e.target.value})} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Product Description<span className="mandatory">*</span></label>
                            <textarea className="form-control" value={productDetail.product_description} onChange={(e)=>setProductDetail({...productDetail,product_description:e.target.value})} ></textarea>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Product Status<span className="mandatory">*</span></label>
                            <select className="form-control" value={productDetail.product_status} onChange={(e)=>setProductDetail({...productDetail,product_status:e.target.value})} >
                                <option value="Unpublished">Unpublished</option>
                                <option value="Published">Published</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">DT Amount</label>
                            <input type="text"  min="1" step="0.01" className="form-control" value={numberWithCommas(productDetail.cf_amount)} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">DTCC Rate (%)</label>
                            <input type="text"  min="1" step="0.01" className="form-control" value={productDetail.cfcc_rate} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Product Quantity<span className="mandatory">*</span></label>
                            <input type="number" min="1" className="form-control" value={productDetail.quantity} onChange={(e)=>setProductDetail({...productDetail,quantity:e.target.value})} />
                        </div>
                        <p><span className="mandatory">*</span>Contact Management for DT amount / DTCC Rate adjustment</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="default" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={handleSubmitUpdateProduct}>
                        Update Product
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function renderTxModal(){
        return(
            <Modal centered size="lg" enforceFocus={false} show={showTxModal} onHide={handleTxModalClose} style={{zIndex:1201}}>
                <Modal.Header closeButton>
                <Modal.Title>View {txDetail.booking_code}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="w-full">
                        <div className="mb-3">
                            <label className="form-label">Product Name</label>
                            <input type="text" className="form-control" value={txDetail.product_name} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Buyer Member ID</label>
                            <input type="text" className="form-control" value={txDetail.buyer_member_id} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Buyer Name</label>
                            <input type="text" className="form-control" value={txDetail.buyer_name} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Buyer Contact<span className="mandatory">*</span></label>
                            <input type="text" className="form-control" value={txDetail.buyer_contact} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">DT Amount</label>
                            <input type="text" className="form-control" value={numberWithCommas(txDetail.cf_amount)} disabled />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Date Purchased</label>
                            <input type="text" className="form-control" value={txDetail.reg_date} disabled />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Date Confirmed </label>
                            <input type="text" className="form-control" value={txDetail.datetime_confirm} disabled />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Date Completed / Cancelled</label>
                            <input type="text" className="form-control" value={txDetail.datetime_complete} disabled />
                        </div>

                        <Divider />

                        <div className="mt-2 mb-3">
                            <label className="form-label">Product Status<span className="mandatory">*</span></label>
                            <select className="form-control" value={txDetail.status} onChange={(e)=>setTxDetail({...txDetail,status:e.target.value})}>
                                <option value="pending">Pending</option>
                                <option value="confirmed">Confirmed</option>
                                <option value="cancelled">Cancelled</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="default" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="contained" onClick={updateSalesStatus}>
                        Update Status
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <Screen pageSlug="my_products">
            <div className="container-fluid mx-auto px-4 py-4">
                <div className='mb-2'>
                    <label className="font-bold text-lg block mb-2">
                        Select Member: 
                    </label>

                    <select value={currentSelectedMember.member_id} onChange={handleSelectChange} className="min-w-80 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none">
                        <option value="">Select Member</option>
                        {profile.members.map((member,index)=>{
                            return (
                                <option key={index} value={member.member_id}>
                                    {member.member_name} ({member.display_member_id}) (DT: {numberWithCommas(member.dt_balance)})
                                </option>
                            );
                        })}
                    </select>
                </div>
                <Divider/>

                    {currentSelectedMember.member_id !== "" && <div className="mt-2 bg-white">
                        <Tabs value={tabIndex} onChange={handleTabChange} >
                            <Tab label="Add New Product"  />
                            <Tab label="My Products"  />
                            <Tab label="My Sales" />
                        </Tabs>
                    </div>}

                    {currentSelectedMember.member_id !== "" && tabIndex === 0 && <div className="mt-2 bg-white px-4 py-4">
                            <div className="mb-3">
                                <label className="form-label">Product Quota Remaining</label>
                                <input type="text" disabled className="form-control" placeholder="0" value={myProducts.my_quota}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Product Image<span className="mandatory">*</span></label>
                                <input type="file" className="form-control" onChange={(e)=>setProductDetail({...productDetail,product_image:e.target.files[0]})} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Product Name<span className="mandatory">*</span></label>
                                <input type="text" className="form-control" value={productDetail.product_name} onChange={(e)=>setProductDetail({...productDetail,product_name:e.target.value})} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Product Description<span className="mandatory">*</span></label>
                                <textarea className="form-control" value={productDetail.product_description} onChange={(e)=>setProductDetail({...productDetail,product_description:e.target.value})} ></textarea>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">DT Amount<span className="mandatory">*</span></label>
                                <input type="number"  min="1" step="0.01" className="form-control" value={productDetail.cf_amount} onChange={(e)=>setProductDetail({...productDetail,cf_amount:e.target.value})} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Product Quantity<span className="mandatory">*</span></label>
                                <input type="number" min="1" className="form-control" value={productDetail.quantity} onChange={(e)=>setProductDetail({...productDetail,quantity:e.target.value})} />
                            </div>

                            <div className="text-center">
                                <Button variant="contained" onClick={() => handleSubmitAddProduct()}>Submit Add Product</Button>
                            </div>
                        </div>
                    }
                    
                    {currentSelectedMember.member_id !== "" && tabIndex === 1 && <div className="mt-2 bg-white px-4 py-4">
                        <div style={{ height: '60vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                                    <DataGrid 
                                        rows={myProductTable} 
                                        columns={
                                            [
                                                { field: 'no', headerName: 'No', width: 100 },
                                                { field: 'product_name', headerName: 'Product Name', width: 200 },
                                                { field: 'cf_amount', headerName: 'DT Amount', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                                                { field: 'cfcc_rate', headerName: 'DTCC Rate', width: 150 },
                                                { field: 'product_status', headerName: 'Status', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'product_approval', headerName: 'Approval', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'product_sales', headerName: 'Product Sales', width: 150 },
                                                { field: 'actions', headerName: 'Actions', width: 150, renderCell: renderActionsCell },
                                            ]
                                        } 
                                        getDetailPanelHeight={() => 'auto'}
                                        pagination
                                        autoPageSize
                                        loading={loading} 
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                            showQuickFilter: true,
                                            },
                                            loadingOverlay: {
                                                variant: 'linear-progress',
                                                noRowsVariant: 'linear-progress',
                                            },
                                        }}
                                    />
                                </div>
                        </div>
                    }

                    {currentSelectedMember.member_id !== "" && tabIndex === 2 && <div className="mt-2 bg-white px-4 py-4">
                        <div style={{ height: '60vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                                    <DataGrid 
                                        rows={mySalesTable} 
                                        columns={
                                            [
                                                { field: 'no', headerName: 'No', width: 100 },
                                                { field: 'booking_code', headerName: 'Transaction Code', width: 200 },
                                                { field: 'product_name', headerName: 'Product Name', width: 150 },
                                                { field: 'cf_amount', headerName: 'DT Amount', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                                                { field: 'quantity', headerName: 'Quantity', width: 150},
                                                { field: 'reg_date', headerName: 'Date Purchased', width: 150},
                                                { field: 'status', headerName: 'Status', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                                { field: 'actions', headerName: 'Actions', width: 150, renderCell: renderSalesActionsCell },
                                            ]
                                        } 
                                        getDetailPanelHeight={() => 'auto'}
                                        pagination
                                        autoPageSize
                                        loading={loading} 
                                        slots={{ toolbar: GridToolbar }}
                                        slotProps={{
                                            toolbar: {
                                            showQuickFilter: true,
                                            },
                                            loadingOverlay: {
                                                variant: 'linear-progress',
                                                noRowsVariant: 'linear-progress',
                                            },
                                        }}
                                    />
                                </div>
                        </div>
                    }
            </div>
            {renderModal()}
            {renderTxModal()}
        </Screen>
    );
}

export default MyProductsPage;