import React, { useEffect, useState} from 'react';
import Screen from '../shared/screen';
import { Divider } from '@mui/material';
import { jdfInstance } from '../../http/http';
import { numberWithCommas } from '../shared/util';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';


function RedeemTransactionsPage(){
    const [transactions,setTransactions] = useState([]);
    const [tableRows,setTableRows] = useState([]);
    const [profile,setProfile] = useState({members:[],email:""});
    const [loading,setLoading] = useState(false);
    const [currentSelectedMember,setCurrentSelectedMember] = useState({display_member_id:"",member_id:"",dt_balance:0});
    const [currentSelectedProduct,setCurrentSelectedProduct] = useState({});
    const [showProductModal,setShowProductModal] = useState(false);
    useEffect(()=>{
        getUserProfile();
        import('bootstrap/dist/css/bootstrap.min.css');
    },[]);

    useEffect(()=>{
        getProductTransactions();
    },[currentSelectedMember]);
    // APIS  ========================================================================================

     async function getProductTransactions(){
        let formData = new FormData();
        formData.append("member_id",currentSelectedMember.member_id);
        const url = "products/getProductTransactions.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setTransactions(response.data.result);
            prepareTransactionsRows(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }

    async function getUserProfile(){
        let formData = new FormData();
    
        const url = "accounts/getUserProfile.php";
        try {
          const response = await jdfInstance.post(url, formData);
          if (response.data.status === 201) {
            setProfile(response.data.result);
          } else {
            window.alert(response.data.msg);
          }
        } catch (e) {
          console.log(e);
        }
    }
    // FUNCTIONS ========================================================================================

        function prepareTransactionsRows(rows){
            let mapped = rows.map((row, index) => {
                return {
                    ...row,
                    id:index,
                    no:index+1,
                    actions: row
                };
            });
            setTableRows(mapped);
        }

        function handleSelectChange(e){
            let val = e.target.value;
            let filtered = profile.members.filter((item)=>item.member_id === val);
            if(filtered.length > 0){
                setCurrentSelectedMember(filtered[0]);
            }else{
                setCurrentSelectedMember({display_member_id:"",member_id:"",dt_balance:0});
            }
        }

        function handleModalClose(){
            setShowProductModal(false);
            setCurrentSelectedProduct({});
        }
    // RENDER ========================================================================================
        function renderActionsCell(props){
            return(
                <div>
                    <IconButton  color="primary" onClick={()=>{
                        //show modal
                        setCurrentSelectedProduct(props.value.product_meta);
                        setShowProductModal(true);
                    }}>
                        <Visibility />
                    </IconButton>
                </div>
            )
        }

        function renderModal(){
            return(
                <Modal centered size="lg" show={showProductModal} onHide={handleModalClose} style={{zIndex:1201}}>
                    <Modal.Header closeButton>
                    <Modal.Title>{currentSelectedProduct.product_name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="w-full md:w-1/4 mb-4" style={{margin:"0 auto"}}>
                            <img
                                src={currentSelectedProduct.product_image}
                                alt={currentSelectedProduct.product_name}
                                className="w-full rounded-lg shadow-lg"
                            />
                        </div>
                        <div className="w-full md:w-1/2">
                            <h4 className="text-lg font-bold text-cyan-500">
                                DT {currentSelectedProduct.cf_amount}
                            </h4>

                            {/* Product Description */}
                            <Divider />
                            <div>
                                <label className="font-bold">Seller Name:</label>
                                <p className="mt-2 ms-2">{currentSelectedProduct.seller_name}</p>
                            </div>
                            <div>
                                <label className="font-bold">Seller Contact:</label>
                                <a className="mt-2 ms-2" href={`tel:${currentSelectedProduct.seller_contact}`}>{currentSelectedProduct.seller_contact}</a>
                            </div>
                            <Divider />
                            <div>
                                <label className="font-bold">Description:</label>
                                <p className="whitespace-pre-wrap mt-2 ms-2">{currentSelectedProduct.product_description}</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="contained" onClick={handleModalClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            )
        }

        return (
            <Screen pageSlug="redeem_transactions">
                <div className="container-fluid mx-auto px-4 py-4">
                    <div className='mb-2'>
                        <label className="font-bold text-lg block mb-2">
                            Select Member: 
                        </label>
    
                        <select value={currentSelectedMember.member_id} onChange={handleSelectChange} className="min-w-80 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none">
                            <option value="">Select Member</option>
                            {profile.members.map((member,index)=>{
                                return (
                                    <option key={index} value={member.member_id}>
                                        {member.member_name} ({member.display_member_id}) (DT: {numberWithCommas(member.dt_balance)})
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <Divider/>
                    <div style={{ height: '80vh', width: '100%',marginTop:10, backgroundColor:"white" }}>
                        <DataGrid 
                            rows={tableRows} 
                            columns={
                                [
                                    { field: 'no', headerName: 'No', width: 100 },
                                    { field: 'booking_code', headerName: 'Transaction ID', width: 200 },
                                    { field: 'redeem_name', headerName: 'Product Name', width: 150 },
                                    { field: 'quantity', headerName: 'Quantity', width: 150 },
                                    { field: 'cf_amount', headerName: 'DT Amount', width: 150, valueFormatter:((value)=> numberWithCommas(value)) },
                                    { field: 'status', headerName: 'Status', width: 150, valueFormatter:((value)=> value.toUpperCase()) },
                                    { field: 'actions', headerName: 'Actions', width: 150, renderCell: renderActionsCell },
                                ]
                            } 
                            getDetailPanelHeight={() => 'auto'}
                            pagination
                            autoPageSize
                            loading={loading} 
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                showQuickFilter: true,
                                },
                                loadingOverlay: {
                                    variant: 'linear-progress',
                                    noRowsVariant: 'linear-progress',
                                },
                            }}
                        />
                    </div>
                    {renderModal()}
                </div>
            </Screen>
        );
}

export default RedeemTransactionsPage;